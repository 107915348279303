import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/projects/app-client/src/components/layout/DocsLayout/DocsLayout.jsx";
import { Figure1, Figure2, Figure3, Figure4, Figure5, Figure6 } from "mocks/edit-view/Figures";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Used for creating or modifying content, configuration or entities, form-based views
provide the most common means of collecting user input to effect mutations.`}</p>
    <h2>{`Composition`}</h2>
    <p>{`Form views may be composed to appear:`}</p>
    <ul>
      <li parentName="ul">{`In the main area of an application view;`}</li>
      <li parentName="ul">{`In a modal window or drawer opened by user activity;`}</li>
      <li parentName="ul">{`As a supporting aside for an application view (such as filtering a list of data).`}</li>
    </ul>
    <p>{`The use of incidental forms throughout a software application is unavoidable, but
with few exceptions, `}<em parentName="p">{`these uses are not views`}</em>{` nor should they be implemented as
such, but rather access a lower-level API as appropriate to the use case.`}</p>
    <h2>{`Create vs. Edit`}</h2>
    <p>{`These two different operations typically use the same view, or parts of it, with
the following key differences:`}</p>
    <ul>
      <li parentName="ul">{`User path to view appropriate to action;`}</li>
      <li parentName="ul">{`View title and instructions explicitly identify `}<em parentName="li">{`what is being created/edited`}</em>{`;`}</li>
      <li parentName="ul">{`Create views do not prepopulate the form with data while edit views do;`}</li>
      <li parentName="ul">{`Identifying information is only available while editing; and`}</li>
      <li parentName="ul">{`Create vs. update action label, styling`}</li>
    </ul>
    <p><strong parentName="p">{`Note:`}</strong>{` The attached figures do not depict the first two points.`}</p>
    <h2>{`Figures`}</h2>
    <p>{`These annotated figures help describe the appearance and functionality of
various aspects of the view.`}</p>
    <h3>{`Create View`}</h3>
    <p>{`A simple form presenting plenty of usability cues to help a user successfully
understand and complete the task set before them.`}</p>
    <p>{`Note the simple structure of the form, which avoids defining field-specific
widths.`}</p>
    <p>{`Forms can be made more beautiful and usable at the expense of greater
complexity to describe the desired visual structure. A suitable rendering solution
will allow us to create simple forms with as little complexity/code as possible,
while leaving open the possibility to enhance rendering on a per-case basis.`}</p>
    <Figure1 mdxType="Figure1" />
    <h3>{`Field Errors`}</h3>
    <p>{`It is imperative to clearly indicate to users where any field input errors occur.
This includes visual cues using color (red) and alternative means (icon) as well as
a clear textual statement explaining the problem or corrective action.`}</p>
    <p>{`Field errors must appear when validation errors are present and a user has touched
the input, or submitted the form. Validation should not be shown/present if the user
has not yet touched an input, regardless of the validity of its current value
(if any).`}</p>
    <Figure2 mdxType="Figure2" />
    <h3>{`Form Errors`}</h3>
    <p>{`At a higher scope than field-specific errors are synchronous, whole form (view-level)
errors, which are typically displayed after the user has attempted to submit
information that could not be accepted for processing.`}</p>
    <p>{`This scope of error differs from those at field-level in a couple key respects:`}</p>
    <ul>
      <li parentName="ul">{`Displayed prominently (as an Alert) above the form itself;`}</li>
      <li parentName="ul">{`Contains one or several different types of key information:`}
        <ul parentName="li">
          <li parentName="ul">{`General information (i.e. "Validation failed", "The server responded with an error")`}</li>
          <li parentName="ul">{`Non-field specific validation errors (i.e. "Invalid credentials provided", "Passwords do not match")`}</li>
          <li parentName="ul">{`Details, including reason for failure, error code, follow up actions, etc.`}</li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`Important:`}</strong>{` Do not use to indicate successful operations. In most
cases, the screen containing the form is removed from view immediately upon
acceptance of the submitted values.`}</p>
    <p>{`Successful operations should be displayed to the user in a Toast message.`}</p>
    <p>{`For the same reason, information accepted for processing, but encounters an error later,
cannot be represented using this form error, and must be conveyed to the user via Toast
as well.`}</p>
    <Figure3 mdxType="Figure3" />
    <h3>{`Modify View`}</h3>
    <p>{`As stated earlier, modify views typically resemble their creation counterparts
with a few exceptions, such as value pre-population and action button changes.
This example also includes an action button to delete the record, as it might
appear if editing the record in a modal window.`}</p>
    <Figure5 mdxType="Figure5" />
    <h3>{`Form Widgets`}</h3>
    <p>{`The term `}<em parentName="p">{`widgets`}</em>{` encompasses the various types of user input that translate
to a rendered presentation to a user. For each collected type of data, there are
often multiple widgets available that will provide the same output.`}</p>
    <p>{`Widgets therefore `}<em parentName="p">{`provide options for view designers`}</em>{` for how best to
represent the data to the user. The drawing below illustrates some options;
more complete examples of the various flavours of form components have been
omitted for time and space.`}</p>
    <Figure4 mdxType="Figure4" />
    <h3>{`Responsive design`}</h3>
    <p>{`Forms should be implemented in a way that sees them collapse responsively when
the screen is collapsed.`}</p>
    <p><strong parentName="p">{`Important:`}</strong>{` Responsive breakpoints operate based on `}<em parentName="p">{`screen dimensions`}</em>{`, not
`}<em parentName="p">{`view dimensions`}</em>{`—`}{`the same column breakpoints may produce unexpected or
undesirable results rendering in a full-screen view compared to a narrow drawer.`}</p>
    <p>{`Care should be taken to ensure that forms are either designed for their
intended target dimensions, or configurable to suit the need.`}</p>
    <Figure6 mdxType="Figure6" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      